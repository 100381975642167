<template>
  <Layout>
    <section class="csms-plan">

      <!-- Page Title Start -->
      <PageHeader :title="title" />
      <!-- Page Title End -->

      <!-- List Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <!-- // List -->
              <div class="table-responsive">
                <b-table class="text-nowrap align-middle" bordered hover
                         :fields="fields" :items="items">
                  <template #head()="data">
                    <span v-if="!$_utils.isEmpty(data.label)">{{ $t(`plan.${data.column}`) }}</span>
                  </template>

                  <template #cell(num)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(description)="data">
                    {{ $_utils.textLengthOverCut(data.item.description, 40, null) }}
                  </template>
                  <template #cell(monthlyFee)="data">
                    {{ data.item.memberPlanId === 10000000000031 ? $_utils.comma(data.item.monthlyFee) : '-' }}
                  </template>
                  <template #cell(etc)="data">
                    <b-button size="sm" variant="warning" @click="edit(data.item.memberPlanId)">
                      <i class="uil uil-edit me-2"></i>
                      <span>{{ $t('btn.edit') }}</span>
                    </b-button>
                  </template>
                </b-table>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- List End -->

      <hr class="mb-4" />

      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="ms-2 mb-0 font-size-18 ion-text-primary">ULTRA {{ $t('plan.planJoinStatus') }}</h4>
          </div>
        </div>
      </div>

      <!-- Plan Join List Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row mb-3 align-items-end">
                <!-- // List PerPage Count -->
                <div class="col-xl-auto">
                  <PerPageSelector v-model="planPagination.perPage"
                                   :option="[10, 25, 50]"
                                   @change="onPerPageChange" />
                </div>
                <!-- // Total Count -->
                <div class="col me-2">
                  <div class="float-end">
                    <total-count :total-count="planPagination.totalCount" />
                  </div>
                </div>
              </div>

              <!-- // List -->
              <div class="table-responsive">
                <b-table class="text-nowrap align-middle" bordered hover
                         show-empty :empty-text="$t('plan.noMember')"
                         :fields="memberFields" :items="memberItems">
                  <template #head()="data">
                    <span v-if="!$_utils.isEmpty(data.label)">{{ $t(`plan.${data.column}`) }}</span>
                  </template>

                  <template #cell(memberName)="data">
                    {{ !$_utils.isEmpty(data.item.memberName) ? data.item.memberName : '-' }}
                  </template>
                  <template #cell(amt)="data">
                    {{ !$_utils.isEmpty(data.item.amt) ? $_utils.comma(data.item.amt) : '-' }}
                  </template>
                  <template #cell(planDate)="data">
                    {{ data.item.startDate }}
                  </template>
                </b-table>
              </div>

              <!-- // Pagination -->
              <div class="row mt-3">
                <div class="col-xl-12">
                  <b-pagination size="sm" class="mb-0" align="center"
                                :total-rows="planPagination.totalCount"
                                :per-page="planPagination.perPage"
                                v-model="planPagination.currentPage"
                                @change="onPageChange" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Plan Join List End -->

      <edit-modal ref="memberPlanEditModal" :selected-id="selectedId" @callback="getItems" />
    </section>
  </Layout>
</template>

<script>
import { http } from '@/auth-api';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Layout from '@/views/layouts/Main.vue';
import PageHeader from '@/components/PageHeader.vue';
import TotalCount from '@/views/components/TotalCount.vue';
import EditModal from '@/views/pages/plan/MemberPlanEditModal.vue';
import PerPageSelector from '@/views/components/PerPageSelector.vue';
import {loadingMethods} from "@/state/helpers";

export default {
  components: {
    Layout, PageHeader, TotalCount, EditModal, PerPageSelector
  },
  data() {
    return {
      title: 'memberPlan',
      selectedId: null,
      searchConditions: [],
      sortDirection: 'DESC',
      pagination: {
        currentPage: 1,
        totalCount : 0,
        perPage: 10
      },
      fields: [
        { key: 'num', label: 'No.', class: 'text-center' },
        { key: 'planName', label: '플랜명', class: 'text-center' },
        { key: 'description', label: '플랜 설명', class: 'text-center' },
        { key: 'discountRate', label: '할인률', class: 'text-center' },
        { key: 'monthlyFee', label: '월 구독료', class: 'text-center' },
        { key: 'updated', label: '마지막 수정일', class: 'text-center' },
        { key: 'etc', label: '', class: 'text-center' }
      ],
      items: null,
      planPagination: {
        currentPage: 1,
        totalCount : 0,
        perPage: 10
      },
      memberFields: [
        { key: 'rnum', label: 'No.', class: 'text-center' },
        { key: 'planName', label: '플랜명', class: 'text-center' },
        { key: 'email', label: '아이디', class: 'text-center' },
        { key: 'memberName', label: '사용자명', class: 'text-center' },
        { key: 'amt', label: '결제 금액', class: 'text-center' },
        { key: 'planDate', label: '플랜 시작일', class: 'text-center' },
        { key: 'authDate', label: '마지막 결제일', class: 'text-center' }
      ],
      memberItems: null,
    }
  },
  mounted() {
    const vm = this;

    vm.getItems();
    vm.getMemberItems();
  },
  methods: {
    ...loadingMethods,
    getItems() {
      const vm = this;
      const _url = '/onmapi/view/MemberPlan/list';

      http.post(_url, {
        page: vm.pagination.currentPage,
        limit: vm.pagination.perPage,
        searchConditions: vm.searchConditions,
        sortCondition: { sortDirection: vm.sortDirection }
      }).then(res => {
        vm.items = res.data.items;
        vm.pagination.totalCount = res.data.totalCount;
      }).catch(err => {
        console.error('Member Plan List Error :: ', err)
      });
    },
    edit(id) {
      const vm = this;

      vm.selectedId = id;

      vm.$nextTick(() => {
        this.$refs.memberPlanEditModal.show();
      });
    },
    async getMemberItems() {
      const vm = this;
      const _url = '/onmapi/view/MemberPlan/list/memberPlanPayment';

      vm.showOverlay();

      await http.post(_url, {
        page: vm.planPagination.currentPage,
        limit: vm.planPagination.perPage,
        searchConditions: vm.searchConditions,
        sortCondition: { sortDirection: vm.sortDirection }
      }).then(res => {
        vm.memberItems = res.data.items;
        vm.planPagination.totalCount = res.data.totalCount;
        vm.hideOverlay();
      }).catch(err => {
        console.error('Member Plan Join Member List Error :: ', err)
        vm.hideOverlay();
      });
    },
    async onPerPageChange() {
      const vm = this;

      if (vm.planPagination.totalCount > 1) {
        vm.planPagination.currentPage = 1;
        await vm.getMemberItems();
      }
    },
    async onPageChange(val) {
      const vm = this;
      vm.planPagination.currentPage = val;

      if (vm.planPagination.totalCount > 1) {
        await vm.getMemberItems();
      }
    },
  }
}
</script>
